import React, {Component} from "react";

import about from "../../../assets/images/about/about-1.jpg";

class AboutTwo extends Component {
    render() {
        let title = "About Us",
            description =
                "Established in 2012, we grew as Dubai became a global cosmopolitan city. " +
                "Our Mission is to enable our valued customers to maximize the value from their IT systems." +
                " Our core values are Commitment,  Accountability, Results and Excellence (CARE). " +
                "We are committed to providing comprehensive office automation services, including data storage, backup, security, and work-from-home solutions for small and medium organizations. ​";

        let whoweare = "Challenges & Opportunities",
            wwadescription = "Digital era has unleashed completely new opportunities and threats. " +
                "Data breaches, Slow Systems, Down Time are all detrimental to a business in the 24x7 global economy. " +
                "Users demand ubiquitous access to data and enterprise applications. " +
                "Cyber threats are continuously evolving while business are trying to keep themselves secure. " +
                "Ransomware is becoming rampant, The WannaCry, NotPetya, Stuxnet - Just to name a few!";
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img height="500px" className="w-80" src={about} alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h3 className="title">{title}</h3>
                                        <p className="description">Established in 2012, we grew as Dubai became a global
                                            cosmopolitan city. Our Mission is to enable our valued customers to maximize
                                            the value from their IT systems. Our core values are Commitment,
                                            Accountability, Results and Excellence (CARE). We are committed to providing
                                            comprehensive office automation services, including data storage, backup,
                                            security, and work-from-home solutions for small and medium organizations.</p>
                                        <h3 className="title">{whoweare}</h3>
                                        <p className="description">{wwadescription}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AboutTwo;
