import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

import logoDefault from "../../assets/images/logo/logo.png";
import logoLight from "../../assets/images/logo/logo.png";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";
import logoSticky from "../../assets/images/logo/logo-sticky.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img id="logo-img" src={logoLight} alt="Future Systems" />;
    } else if (logo === "dark") {
      logoUrl = <img id="logo-img" src={logoDark} alt="Future Systems" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img id="logo-img" src={logoSymbolDark} alt="Future Systems" />;
    } else if (logo === "symbol-light") {
      logoUrl = <img id="logo-img" src={logoSymbolLight} alt="Future Systems" />;
    } else {
      logoUrl = <img id="logo-img" src={logoDefault} alt="Future Systems" />;
    }

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      const logoImg = document.querySelector("#logo-img");
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
        if (logoImg) {
          logoImg.src = logoSticky;
        }
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
        if (logoImg) {
          logoImg.src = logoDefault;
        }
      }
    });

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color} header--fixed`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="has-droupdown">
                  <a href="#">Services</a>
                  <ul className="submenu">
                    <li>
                      <Link to="/it-consultancy-mp">IT Consultancy</Link>
                    </li>
                    <li>
                      <Link to="/network-solutions-mp">Network Solutions</Link>
                    </li>
                    <li>
                      <Link to="/infrastructure">Infrastructure</Link>
                    </li>
                    <li>
                      <Link to="/cloud-services">Cloud Services</Link>
                    </li>
                    <li>
                      <Link to="/printers-and-scanners">Hardware Supply Services</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <a href="https://g.page/r/CVYUlmBCeL3TEAE/review" target="_blank">Google Reviews</a>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <a
                className="rn-btn"
                href="/contact"
              >
                <span>Contact</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
