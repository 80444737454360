import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/telephone-systems.png";
import serviceImg2 from "../assets/images/service/telephone-systems2.png";

class TelephoneSystems extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Telephone Systems"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Telephone Systems</h2>
                                    <p>Landline & VoIP solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Our Telephone Systems services offer reliable and efficient
                                                        communication solutions tailored to your business needs. We
                                                        provide end-to-end services, encompassing the installation,
                                                        configuration, and maintenance of state-of-the-art telephone
                                                        systems. Whether you require a traditional landline setup or
                                                        advanced VoIP solutions, our expert team ensures seamless
                                                        integration and optimal performance to meet your unique
                                                        requirements.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        With our comprehensive approach, you can count on a
                                                        communication system that supports your business operations
                                                        effectively. We prioritize reliability and efficiency, ensuring
                                                        that your telephone systems are always up and running smoothly.
                                                        Trust us to deliver cutting-edge communication solutions that
                                                        enhance connectivity and productivity within your organization.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default TelephoneSystems;
