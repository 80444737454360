import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/csm.png";
import serviceImg2 from "../assets/images/service/csm2.png";

class ComputerSystemsManagement extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Computer Systems Management"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Computer Systems Management</h2>
                                    <p>Robust & Reliable Technology</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Efficient management of your computer systems is crucial for
                                                        maintaining seamless business continuity. Our Computer Systems
                                                        Management services are designed to cover every aspect of your
                                                        IT infrastructure, from initial installation and configuration
                                                        to ongoing maintenance and support. We understand that any
                                                        downtime can significantly impact your operations, which is why
                                                        we prioritize keeping your systems running smoothly and
                                                        efficiently.
                                                    </p>
                                                    <p>
                                                        By leveraging our expertise, you can ensure that your technology
                                                        backbone is robust and reliable, allowing you to focus on what
                                                        you do best—growing your business and serving your customers.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Our comprehensive services are tailored to meet the unique needs
                                                        of your organization. We provide proactive monitoring and
                                                        regular updates to prevent potential issues before they arise,
                                                        minimizing disruptions and maximizing productivity. Our team of
                                                        skilled professionals is always ready to assist with any
                                                        technical challenges, ensuring that your systems are optimized
                                                        for peak performance.
                                                    </p>
                                                    <p>
                                                        With our support, you can reduce the burden of IT management,
                                                        freeing up valuable time and resources to concentrate on your
                                                        core business activities. Trust us to handle your computer
                                                        systems with the utmost care and expertise, so you can achieve
                                                        your business goals with confidence and peace of mind.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default ComputerSystemsManagement;
