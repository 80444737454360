import React, {useState} from 'react';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>
    )
}

function ContactForm({props}) {
    const [result, showresult] = useState(false);

    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        console.log('Captcha value:', value);
        setCaptchaValue(value);
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        const name = e.target.elements.fullname.value
        const email = e.target.elements.email.value
        const phone = e.target.elements.phone.value
        const subject = e.target.elements.subject.value
        const msg = e.target.elements.message.value

        try {
            await fetch(`https://sendemail-f77nt4dn6q-uc.a.run.app?name=${name}&email=${email}&phone=${phone}&subject=${subject}&msg=${msg}`);

            e.target.reset();
            showresult(true);

        } catch (error) {
            console.error('Error fetching data:', error);

            e.target.reset();
            showresult(false);
        }

    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                />
            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit"
                        id="mc-embedded-subscribe">Send
                </button>
            </div>

            <div className="rn-form-group">
                {result ? <Result/> : null}
            </div>
        </form>
    )
}

export default ContactForm;
