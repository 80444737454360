import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/network-security.png";
import serviceImg2 from "../assets/images/service/network-security2.png";

class NetworkSecurity extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Network Security"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Network Security</h2>
                                    <p>Firewalls, Intrusion Detection Systems, Encryption Protocols</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Protecting your business data is our top priority, and we take
                                                        this responsibility very seriously. Our network security
                                                        services are designed to offer comprehensive protection against
                                                        a wide range of cyber threats. By implementing advanced security
                                                        measures such as firewalls, intrusion detection systems, and
                                                        encryption protocols, we ensure that your network is safeguarded
                                                        from unauthorized access, data breaches, and other security
                                                        risks.
                                                    </p>
                                                    <p>
                                                        These robust defenses are continuously monitored and updated to
                                                        adapt to the ever-evolving landscape of cyber threats, providing
                                                        you with peace of mind and allowing you to focus on your core
                                                        business activities without worrying about potential security
                                                        breaches.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        In addition to these technical measures, we also provide regular
                                                        security assessments and audits to identify and address any
                                                        vulnerabilities in your network. Our team of experienced
                                                        security professionals is dedicated to staying ahead of the
                                                        latest threats and employing best practices to protect your
                                                        valuable data. We understand that every business has unique
                                                        security needs, which is why we tailor our services to meet your
                                                        specific requirements.
                                                    </p>
                                                    <p>
                                                        Whether you are a small business or a large enterprise, our
                                                        network security solutions are designed to provide you with the
                                                        highest level of protection, ensuring that your business
                                                        operations remain secure and uninterrupted. Trust us to be your
                                                        partner in safeguarding your digital assets and maintaining the
                                                        integrity of your network.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default NetworkSecurity;
