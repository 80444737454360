import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/printers-scanners.png";
import serviceImg2 from "../assets/images/service/printers-scanners2.png";

class PrintersScanners extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Hardware Supply Services"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Hardware Supply Services</h2>
                                    <p>Printing, network data switches & server components</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        At Future Systems, we specialize in providing a
                                                        comprehensive range of  refurbished network equipment, including servers,
                                                        workstations, Cisco routers, storage solutions, Apple products,
                                                        computers, printers, and all related parts and accessories. Our
                                                        expertise extends particularly to HP and Dell servers and
                                                        storage units, as well as Cisco networking equipment and Apple
                                                        products.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        We offer a full suite of business-class workstations, desktops,
                                                        laptops, and essential networking components to support your IT
                                                        infrastructure upgrades. Our solutions leverage advanced
                                                        technologies to manage devices and processes with a focus on
                                                        safety, reliability, efficiency, and sustainability. Trust us to
                                                        deliver the equipment and expertise needed to power your
                                                        business with confidence.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default PrintersScanners;
