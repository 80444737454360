import React, {Component, Fragment} from "react";
import Slider from "react-slick";
import {slideSlick} from "../page-demo/script";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp, FiWifi} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import ServiceThree from "../elements/service/ServiceThree";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";


const SlideList = [
    {
        textPosition: "text-center",
        bgImage: "bg_image--5",
        category: "",
        title: "Physical Security Services",
        description:
            "Access Control Systems, CCTV Surveillance, Home Automation.",
        buttonText: "Contact Us",
        buttonLink: "/contact",
    },
    {
        textPosition: "text-center",
        bgImage: "bg_image--5",
        category: "",
        title: "Cloud Computing Services",
        description:
            "Planning & Support for Azure, AWS, Zoho services.",
        buttonText: "Contact Us",
        buttonLink: "/contact",
    },
    {
        textPosition: "text-center",
        bgImage: "bg_image--5",
        category: "",
        title: "Network Security",
        description:
            "Detect and protect threats to your systems against cyber attacks.",
        buttonText: "Contact Us",
        buttonLink: "/contact",
    }
];

class Startup extends Component {
    render() {
        const PostList = BlogContent.slice(0, 3);
        return (
            <Fragment>
                <Helmet pageTitle="Future Systems"/>
                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                // Start Single Slider
                                <div
                                    className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                                    key={index}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ""}
                                                    {value.title ? (
                                                        <div><h2 className="theme-gradient">
                                                            {value.title}
                                                        </h2>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.description ? (
                                                        <p className="description">{value.description}</p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                // End Single Slider
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area ptb--40">
                    <AboutTwo/>
                </div>
                {/* End About Area */}
                <div className="service-area ptb--75  bg_image bg_image--3">
                    <div className="container">
                        <ServiceThree/>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </Fragment>
        );
    }
}

export default Startup;
