// React Required
import React, {Component} from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Startup from "./home/Startup";

// Dark Home Layout
// Element Layout
import SoftwareSetup from "./elements/SoftwareSetup";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout
import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import ITConsultancy from "./elements/ITConsultancy";
import NetworkSecurity from "./elements/NetworkSecurity";
import ComputerSystemsManagement from "./elements/ComputerSystemsManagement";
import CloudServices from "./elements/CloudServices";
import PrintersScanners from "./elements/PrintersScanners";
import NetworkingSolutions from "./elements/NetworkingSolutions";
import ServerSupport from "./elements/ServerSupport";
import TelephoneSystems from "./elements/TelephoneSystems";
import CCTV from "./elements/CCTV";
import AccessControlSystems from "./elements/AccessControlSystems";
import TechnicalSupport from "./elements/TechnicalSupport";
import ITConsultancyMP from "./elements/ITConsultancyMP";
import NetworkSolutionsMP from "./elements/NetworkSolutionsMP";
import Infrastructure from "./elements/Infrastructure";
import CloudServicesMP from "./elements/CloudServicesMP";
import SDWAN from "./elements/SDWAN";
import AboutTwo from "./component/HomeLayout/homeOne/AboutTwo";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Startup} />

            {/* Element Layot */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/it-consultancy-mp`}
              component={ITConsultancyMP}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/network-solutions-mp`}
              component={NetworkSolutionsMP}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/infrastructure`}
              component={Infrastructure}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cloud-services-mp`}
              component={CloudServicesMP}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/software-setup`}
              component={SoftwareSetup}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/it-consultancy`}
              component={ITConsultancy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/network-security`}
              component={NetworkSecurity}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/sd-wan`}
              component={SDWAN}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/computer-systems-management`}
              component={ComputerSystemsManagement}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cloud-services`}
              component={CloudServices}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/printers-and-scanners`}
              component={PrintersScanners}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/networking-solutions`}
              component={NetworkingSolutions}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/server-support`}
              component={ServerSupport}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/telephone-systems`}
              component={TelephoneSystems}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cctv-installation`}
              component={CCTV}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/access-control-systems`}
              component={AccessControlSystems}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/technical-support`}
              component={TechnicalSupport}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details`}
              component={PortfolioDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            />

            {/* Blocks Elements  */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={Team}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/counters`}
              component={Counters}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/testimonial`}
              component={Testimonial}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio`}
              component={Portfolio}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/video-popup`}
              component={VideoPopup}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gallery`}
              component={Gallery}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/clint-logo`}
              component={Brand}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/progressbar`}
              component={ProgressBar}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact-form`}
              component={ContactForm}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/google-map`}
              component={GoogleMap}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/columns`}
              component={Columns}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/pricing-table`}
              component={PricingTable}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
