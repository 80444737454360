import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/it-consultancy.png";
import serviceImg2 from "../assets/images/service/it-consultancy2.png";

class ITConsultancy extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="IT Consultancy"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">IT Consultancy</h2>
                                    <p>Technology assessment, System Integration, Digital Transformation</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Our IT consultancy services offer expert guidance to help you
                                                        navigate the complexities of technology and make informed
                                                        decisions for your business. We understand that every business
                                                        faces unique challenges, and we are committed to working closely
                                                        with you to identify and address these challenges. Our team of
                                                        experienced consultants provides strategic advice and tailored
                                                        solutions that align with your specific goals.
                                                    </p>
                                                    <p>
                                                        Whether you need a comprehensive technology assessment, seamless
                                                        system integration, or a full-scale digital transformation, we
                                                        have the expertise to support you. Our IT strategy development
                                                        services ensure that your technology investments are aligned
                                                        with your business objectives, empowering you to leverage
                                                        technology effectively, enhance productivity, and drive
                                                        innovation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        From the initial consultation to the implementation of advanced
                                                        IT solutions, we are dedicated to helping your business achieve
                                                        technological excellence and sustained growth. Our consultancy
                                                        services are designed to provide you with the insights and tools
                                                        needed to stay competitive in today’s fast-paced digital
                                                        landscape. We believe in a collaborative approach, working
                                                        hand-in-hand with your team to ensure that our solutions are not
                                                        only effective but also practical and scalable.
                                                    </p>
                                                    <p>
                                                        Let us be your trusted partner in navigating the ever-evolving
                                                        world of technology, and together, we can achieve remarkable
                                                        results. With our support, you can focus on what you do
                                                        best—running your business—while we take care of your IT needs.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default ITConsultancy;
