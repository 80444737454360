import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/technical-support.png";
import serviceImg2 from "../assets/images/service/technical-support2.png";

class TechnicalSupport extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Technical Support"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Technical Support</h2>
                                    <p>Troubleshooting, Maintenance & Updates</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Our Technical Support services are here to keep your IT systems
                                                        running smoothly. We understand that technology is the backbone
                                                        of your business, and any disruption can lead to significant
                                                        downtime and loss. That's why we offer comprehensive support to
                                                        resolve any technical issues quickly and efficiently. Our team
                                                        of experts is well-versed in a wide range of IT problems, from
                                                        simple software glitches to complex network issues. With our
                                                        prompt and reliable service, you can rest assured that your IT
                                                        infrastructure is in capable hands.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Our dedicated support team is always ready to assist you with
                                                        troubleshooting, maintenance, and updates. We prioritize your
                                                        business continuity and work tirelessly to ensure minimal
                                                        disruption to your operations. Regular maintenance and timely
                                                        updates are crucial for preventing potential problems and
                                                        enhancing the performance of your systems. By choosing our
                                                        Technical Support services, you are investing in the longevity
                                                        and reliability of your IT infrastructure, allowing you to focus
                                                        on your core business activities with peace of mind.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default TechnicalSupport;
