import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/cloud-services.png";
import serviceImg2 from "../assets/images/service/cloud-services2.png";

class CloudServices extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Cloud Services"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Cloud Services</h2>
                                    <p>Planning & Support for Azure, AWS, Zoho services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Unlock the full potential of your business with our
                                                        comprehensive Cloud Services. Whether you need reliable cloud
                                                        storage and backup solutions or full-scale cloud computing
                                                        capabilities, we have you covered. Our platform is designed to
                                                        provide the best performance and security, ensuring that your
                                                        data is always safe and accessible.
                                                    </p>
                                                    <p>
                                                        By leveraging our cloud services, you can significantly reduce
                                                        operational costs, allowing you to allocate resources more
                                                        efficiently. Additionally, our solutions offer unparalleled
                                                        flexibility, enabling you to scale your operations seamlessly as
                                                        your business grows.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Moreover, our cloud technologies are tailored to enhance
                                                        collaboration within your organization. With real-time access to
                                                        data and applications, your team can work together more
                                                        effectively, regardless of their physical location. This
                                                        increased collaboration can lead to improved productivity and
                                                        innovation, driving your business forward.
                                                    </p>
                                                    <p>
                                                        Our cloud services are designed to meet the unique needs of your
                                                        business, providing you with the tools and support necessary to
                                                        stay competitive in today’s fast-paced digital landscape.
                                                        Embrace the future of technology with our cloud solutions and
                                                        experience the benefits of a more agile and connected business
                                                        environment.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default CloudServices;
