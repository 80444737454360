import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import {FiChevronUp, FiGlobe, FiLock, FiWifi} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiGlobe/>,
        title: 'Networking Solutions',
        description: 'Secure LAN & WAN Setup.',
        page: '/networking-solutions'
    },
    {
        icon: <FiLock/>,
        title: 'Network Security',
        description: 'Firewalls, Intrusion Detection.',
        page: '/network-security'
    },
    {
        icon: <FiWifi/>,
        title: 'SD WAN',
        description: 'Controlling Network Traffic.',
        page: '/sd-wan'
    },
]

class NetworkSolutionsMP extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Network Solutions'/>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Network Solutions'} bgimg={'bg_image--5'}/>
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Network Solutions</h2>
                                    <p>Secure LAN & WAN Setup</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.page}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>


            </React.Fragment>
        )
    }
}

export default NetworkSolutionsMP;