import React, {Component} from "react";
import {
    FiCast,
    FiLayers,
    FiUsers,
    FiMonitor,
    FiCode,
    FiHeadphones,
    FiLock,
    FiServer,
    FiCloud,
    FiPrinter, FiGlobe, FiWind, FiWifi, FiCamera, FiKey, FiPhone
} from "react-icons/fi";
import {FaCogs, FaLaptop} from "react-icons/all";

const ServiceList = [
    {
        icon: <FiCode/>,
        title: 'Software Setup',
        description: 'ERP, Consultation, Training.',
        page: '/software-setup'
    },
    {
        icon: <FiHeadphones/>,
        title: 'IT Consultancy',
        description: 'Technology assessment.',
        page: '/it-consultancy'
    },
    {
        icon: <FiLock/>,
        title: 'Network Security',
        description: 'Firewalls, Intrusion Detection.',
        page: '/network-security'
    },
    {
        icon: <FiMonitor/>,
        title: 'Computer Systems Management',
        description: 'Robust & Reliable Technology.',
        page: '/computer-systems-management'
    },
    {
        icon: <FiCloud/>,
        title: 'Cloud Services',
        description: 'Planning & Support for Azure, AWS, Zoho services.',
        page: '/cloud-services'
    },
    {
        icon: <FiPrinter/>,
        title: 'Hardware Supply Services',
        description: 'Printing & Networking Equipment.',
        page: '/printers-and-scanners'
    },
    {
        icon: <FiGlobe/>,
        title: 'Networking Solutions',
        description: 'Secure LAN & WAN Setup.',
        page: '/networking-solutions'
    },
    {
        icon: <FiServer/>,
        title: 'Server Support',
        description: 'Server installation & configuration.',
        page: '/server-support'
    },
    {
        icon: <FiWifi/>,
        title: 'Telephone Systems',
        description: 'Landline & VoIP solutions.',
        page: '/telephone-systems'
    },
    {
        icon: <FiCamera/>,
        title: 'CCTV Installation & Management',
        description: 'High Quality Surveillance.',
        page: '/cctv-installation'
    },
    {
        icon: <FiKey/>,
        title: 'Access Control Systems',
        description: 'Reliable Biometric Readers.',
        page: '/access-control-systems'
    },
    {
        icon: <FiPhone/>,
        title: 'Technical Support',
        description: 'Troubleshooting & Maintenance.',
        page: '/technical-support'
    }, {
        icon: <FiWifi/>,
        title: 'SD WAN',
        description: 'Controlling Network Traffic.',
        page: '/sd-wan'
    },
]

class ServiceTwo extends Component {
    render() {
        let title = 'Services',
            description = '-TO BE FILLED-';
        return (
            <React.Fragment>
                <div className="service-area ptb--50 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Services</h2>
                                    <p>Security, Telecom, Network Security & Management.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.page}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ServiceTwo;
