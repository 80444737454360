import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import mapimg from "../assets/images/about/map.png";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import about from "../assets/images/bg/bg-image-4.jpg";
import {FaWhatsapp} from "react-icons/fa";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact'/>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pb--100 bg_image bg_image--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact With Us</h2>
                                    <p>We're here to help!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 bg_color--5">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact With Phone Number</h4>
                                        <p><FaWhatsapp size={20}></FaWhatsapp><a href="tel:+971 056 690 0707"> (+971)
                                            056 690 0707</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:contact@futuresystems.ae">contact@futuresystems.ae</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address-2">
                                    <div className="icon">
                                        <FiMapPin/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>Office 101, Ali Ubaid Bin Rasheed Tower B, Al-Qusais 3 Dubai, UAE</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <ContactTwo/>
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}

                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '450px', width: '100%', marginBottom:'100px', textAlign: 'center'}} className="align-content-center">
                        <img height="480px" src={mapimg} alt="location" />
                    </div>
                </div>
                {/* End Contact Map  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>

            </React.Fragment>
        )
    }
}

export default Contact