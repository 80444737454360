import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/software-setup.png";
import serviceImg2 from "../assets/images/service/software-setup2.png";

class SoftwareSetup extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Software Setup"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Software Setup</h2>
                                    <p>ERP, Consultation, Training</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        Our ERP and Other Business Software services are designed to
                                                        streamline your business processes and significantly enhance
                                                        operational efficiency. We specialize in the implementation and
                                                        configuration of enterprise resource planning (ERP) systems and
                                                        other critical business software. Our approach begins with an
                                                        in-depth initial consultation to understand your unique business
                                                        needs and challenges.
                                                    </p>
                                                    <p>
                                                        Based on this understanding, we assist you in selecting the most
                                                        suitable software solutions that align with your strategic
                                                        goals. Our team then handles the entire installation process,
                                                        ensuring that the software is seamlessly integrated with your
                                                        existing systems. We also offer extensive customization options
                                                        to tailor the software to your specific requirements, ensuring
                                                        that it supports your business operations effectively.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content">
                                            <div className="col-lg-10 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        In addition to implementation and customization, we provide
                                                        comprehensive training to ensure that your team can fully
                                                        leverage the new software. Our training programs are designed to
                                                        be practical and user-friendly, enabling your staff to quickly
                                                        become proficient with the new tools. We believe that a smooth
                                                        transition is crucial for the success of any software
                                                        implementation, and we are committed to providing ongoing
                                                        support to address any issues that may arise.
                                                    </p>
                                                    <p>
                                                        By partnering with us, you can be confident that your business
                                                        will benefit from enhanced efficiency, improved data management,
                                                        and streamlined processes. Our ERP and business software
                                                        services are tailored to help you achieve your operational goals
                                                        and drive sustained growth. Let us help you harness the power of
                                                        technology to transform your business.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg2}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default SoftwareSetup;
