import React, {Component} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/network-security.png";
import serviceImg2 from "../assets/images/service/service-02.png";

class SDWAN extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="SD WAN"/>
                {/* End Pagehelmet  */}

                <Header
                    headertransparent="header--transparent"
                    colorblack="color--black"
                    logoname="logo.png"
                />

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pb--100 bg_image bg_image--5"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">SD WAN</h2>
                                    <p>Controlling Network Traffic</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40">
                                            <div className="col-lg-2 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src={serviceImg}
                                                        alt="Service Images"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                        We optimize the management of wide area network by using
                                                        software to control network traffic. It enhances performance,
                                                        security, and flexibility, enabling seamless connectivity across
                                                        multiple locations, and efficiently integrates with cloud
                                                        services for modern business needs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer/>
            </React.Fragment>
        );
    }
}

export default SDWAN;
